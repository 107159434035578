import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import Profile from "pages/Authentication/user-profile"

// Authentication related pages
import Login from "pages/Authentication/Login"
import Logout from "pages/Authentication/Logout"
import Register from "pages/Authentication/Register"
import ForgetPwd from "pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "pages/Dashboard/index"
import UserManagement from "pages/UserManagement"
import ContentManagement from "pages/ContentManagement"
import UserProfile from "pages/UserManagement/UserProfile"
import Levels from "pages/Setup/Levels"
import PackageAndSubscription from "pages/Setup/Package"
import Contents from "pages/ContentManagement/Contents.js"
import Units from "pages/ContentManagement/Units"
import ExamsAndTests from "pages/ContentManagement/ExamsAndTests"


const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/user-management", component: UserManagement },
  { path: "/content-management/units/:id", component: Contents },
  { path: "/content-management/exams-and-tests", component: ExamsAndTests },
  { path: "/content-management/units", component: Units },

  { path: "/setup/levels", component: Levels },
  { path: "/setup/package-and-subscription", component: PackageAndSubscription },
  // //profile
  { path: "/profile", component: Profile },
  { path: "/user-profile/:id", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { authProtectedRoutes, publicRoutes }
