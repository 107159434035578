import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = props => {
  const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const splitPath = props.location.pathname.split('/');
    const pathName = splitPath.length > 2
      ? splitPath.slice(0, 3).join('/')  // Take first 3 segments if available
      : splitPath.join('/');  // Otherwise, join what is available (2 segments)

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);


  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">

            <li>
              <Link to="/#"
              //  className="has-arrow"
              >
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboards")}</span>
              </Link>
              {/* <ul className="sub-menu">
                <li>
                  <Link to="/dashboard">{props.t("Default")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Saas")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Crypto")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Blog")}</Link>
                </li>
                <li>
                  <Link to="#">
                    <span className="badge rounded-pill text-bg-success float-end" key="t-new">New</span>
                    {props.t("Jobs")}
                  </Link>
                </li>
              </ul> */}
            </li>
            <li>
              <Link to="/content-management" className="has-arrow">
                <i className="bx bx-file"></i>
                <span>{props.t("Content Management")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/content-management/units">
                    {props.t("Units & Contents")}
                  </Link>
                </li>
                <li>
                  <Link to="/content-management/exams-and-tests">
                    {props.t("Exams and Tests")}
                  </Link>
                </li>


              </ul>
            </li>
            <li>
              <Link to="/user-management" className="">
                <i className="fas fa-users    "></i>
                <span>{props.t("User Management")}</span>
              </Link>
            </li>
            <li>
              <Link to="/setup" className="has-arrow">
                <i className=" fas fa-wrench"></i>
                <span>{props.t("Setup")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/setup/levels">
                    {props.t("Levels")}
                  </Link>
                </li>
                <li>
                  <Link to="/setup/package-and-subscription">
                    {props.t("Package")}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/analytics" className="">
                <i className="bx bx-calendar"></i>
                <span>{props.t("Analytics")}</span>
              </Link>
            </li>
            <li>
              <Link to="/logs" className="">
                <i className="fas fa-history"></i>
                <span>{props.t("Logs")}</span>
              </Link>
            </li>
            <li className="mt-3 pt-3">
              <Link to="/login" onClick={() => localStorage.clear()}>
                <i className="bx bx-log-out-circle"></i>
                <span>{props.t("Logout")}</span>
              </Link>
            </li>






            {/* <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-store"></i>
                <span>{props.t("Ecommerce")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#">{props.t("Products")}</Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Product Detail")}
                  </Link>
                </li>
                <li>
                  <Link to="#">{props.t("Orders")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Customers")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Cart")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Checkout")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Shops")}</Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Add Product")}
                  </Link>
                </li>
              </ul>
            </li> */}



          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
