import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Card, Accordion, Col, Form, Row, Spinner } from "react-bootstrap";
import TableTemplate from "components/TableTemplate"

import toast from "react-hot-toast";
import moment from "moment"
import { Link } from "react-router-dom";

import * as setup from "models/setup";

import AddEditContent from "./AddEditContent"

const Contents = props => {
  const [contents, setContents] = useState([])
  const [levelTypes, setLevelTypes] = useState([""])
  const [units, setUnits] = useState([])

  const [toEdit, setToEdit] = useState(false)
  const [selected, setSelected] = useState(null)
  //processing variables
  const [isLoaded, setIsLoaded] = useState(false)

  //modal
  const [showAddEditContent, setShowAddEditContent] = useState(false)
  const [showdeleteContent, setShowDeleteContent] = useState(false)

  const {
    match: { params },
  } = props

  const columns = [
    {
      name: "Actions",

      cell: (row) => {
        return (
          <div className="d-flex justify-content-center "
          >
            <button
              className="btn btn-sm text-info text-nowrap "
              onClick={() => {
                setShowAddEditUnit(true)
                setSelected(row)
                setToEdit(true)
              }}
            >
              <p

                style={{ fontSize: 11 }}
                className="ms-2  my-0 py-0"
              >
                View
              </p>
            </button>
            <button
              className="btn btn-sm text-info text-nowrap "
              onClick={() => {
                setShowAddEditUnit(true)
                setSelected(row)
                setToEdit(true)
              }}
            >
              <p

                style={{ fontSize: 11 }}
                className="ms-2  my-0 py-0"
              >
                Edit
              </p>
            </button>
            <button className="btn btn-sm text-danger text-nowrap "
              onClick={() => {
                setShowDeleteUnit(true)
                setSelected(row)
              }}>
              Delete
            </button>


          </div>
        )
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '150px'
    },
    { name: "Name", selector: row => row?.topicName },
    { name: "Level", selector: row => row?.level },

    // {
    //   name: "Status",
    //   selector: row =>
    //     row?.levelTypeStatus === "active" ? "Active" :
    //       row?.levelTypeStatus === "inactive" ? "Inactive" :
    //         row?.levelTypeStatus === "suspended" ? "Suspended" : null,

    //   conditionalCellStyles: [
    //     {
    //       when: (row) => row?.levelTypeStatus === 'active',
    //       style: {
    //         color: 'green',
    //       },
    //     },
    //     {
    //       when: (row) => row?.levelTypeStatus === 'inactive',
    //       style: {
    //         color: 'orange',
    //       },
    //     },
    //     {
    //       when: (row) => row?.levelTypeStatus === 'suspended',
    //       style: {
    //         color: 'danger',
    //       },
    //     },
    //   ]
    // },
    { name: "Updated", selector: row => moment(row.updatedAt).format('LL'), },
    { name: "Created", selector: row => moment(row.createdAt).format('LL'), }


  ]
  const subHeaderNav = (
    <div className=" d-flex  pb-2 justify-content-end">
      <button className="btn btn-primary" onClick={() => { setShowAddEditContent(true) }}>
        Add Content
      </button>
    </div>
  )
  const getAllLevelTypes = (params) => {
    const profile = JSON.parse(localStorage.getItem("authUser"));
    const token = profile?.token;
    setIsLoaded(false)
    setup.getAllLevelTypes(params, token)
      .then(resp => {
        const data = resp.levelTypes
        setLevelTypes(data)
        setIsLoaded(true)
      }).catch(err => {
        toast.error(err?.response?.data?.message)
      })
  }

  const getUnitById = () => {
    const profile = JSON.parse(localStorage.getItem("authUser"));
    const token = profile?.token;
    setup.getUnitById(params?.id, token)
      .then(resp => {
        const data = resp?.unit
        setContents(data?.contents)
        setIsLoaded(true)
      }).catch(err => {
        console.log(err)
        toast.error(err?.response?.data?.message)
      })
  }
  useEffect(() => {
    getUnitById()
    getAllLevelTypes()
  }, [])
  return (
    <div className="page-content">
      <Card>

        <Card.Body>

          <TableTemplate
            columns={columns}
            rows={contents}
            dataLoaded={isLoaded}
            subHeaderNav={subHeaderNav}
          // expandableRows={true}
          // expandableRowsComponent={ExpandableRowComponent}
          />

        </Card.Body>
      </Card>
      <AddEditContent
        showModal={showAddEditContent}
        setShowModal={setShowAddEditContent}
        toEdit={toEdit}
        setToEdit={setToEdit}
        selected={selected}
        setSelected={setSelected}
        fetchData={() => getUnitById()}
        levelTypes={levelTypes}
        unitId={params?.id}
      />
    </div>
  )
}

Contents.propTypes = {}

export default Contents