import React from 'react'
import PropTypes from 'prop-types'

const ExamsAndTests = props => {
  return (
    <div>index</div>
  )
}

ExamsAndTests.propTypes = {}

export default ExamsAndTests